import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from "react-redux";

import styles from './PopupInfo.module.scss';
import Background from "../../assets/images/login_logo.png"

import ButtonMain from "../buttons/ButtonMain/ButtonMain";
import {clearGeneralError} from "../../redux/rootReducer";

const PopupInfo = ({ text, action }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <div className={styles.overlay}>
            <div className={styles.wrapper}>
                <img src={Background} className={styles.backgroung} alt={''} />
                <div className={styles.info}>
                    {text ? text : '予期せぬ不具合が発生しました'}
                </div>
                <ButtonMain
                    text={'ok'}
                    onClick={() => {
                        dispatch(clearGeneralError());
                        navigate('/');
                        if (action) {
                            action();
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default PopupInfo;