import React, {useState, useEffect, useRef} from 'react';
import {NavLink, Routes, useNavigate, useSearchParams} from 'react-router-dom';
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {CSSTransition} from "react-transition-group";

import styles from './Home.module.scss';
import CTABackground from "../../assets/images/login_main_container.png"
import Logo from "../../assets/images/logo.png"
import IconInfo from "../../assets/images/icon-info.png";
import CategoryTitleImg from "../../assets/images/category_background.png";

import ButtonMain from "../../components/buttons/ButtonMain/ButtonMain";
import {
    CONFIG_LOGIN,
    ageInputData,
    MIN_YEARS_TO_PURCHASE,
    API,
    REDIRECT_URL_AFTER_CLAIM,
    ENV
} from "../../shared/constants";
import Loading from "../../components/Loading/Loading";
import PopupInfo from "../../components/PopupInfo/PopupInfo";
import {logUserIn, showGeneralError} from "../../redux/rootReducer";
import withRequestHandler from "../../HOC/withRequestHandler";
import OfferPopupInfo from "../../components/OfferPopupInfo/OfferPopupInfo";
import OffersLayoutsManager from "../../components/offers/offersLayouts/OffersLayoutsManager";
import FindId from "../FindId/FindId";

const INPUT_REQUIRE_LENGTH_ID = 16;
const INPUT_REQUIRE_LENGTH_NAME = 20;
const DailyFreeGiftBanner = API.url + 'banner/gift?v=' + Date.now();

const renderOffersNormal = (category, claimFreeGift, openPopupInfo, config) => {
    const offersWithTimers = category.packs.filter(cat => cat.endTime);

    return (
        <div className={styles.itemOffersWrapper}>
            <CSSTransition in appear timeout={300} classNames={'fade-in-slide-down'}>
                <div className={styles.itemOffersTitle}>
                    <img className={styles.categoryTitleImg} src={CategoryTitleImg} alt={''} />
                    <div className={styles.categoryTitleName}>{category.name}</div>
                </div>
            </CSSTransition>
            <div className={styles.itemOfferPackage}>
                {category.packs.length ?
                    category.packs.map((offer, index) => (
                        <OffersLayoutsManager
                            offer={offer}
                            categoryId={category.order}
                            key={index}
                            claimFreeGift={claimFreeGift}
                            openPopupInfo={openPopupInfo}
                            noTimers={!offersWithTimers.length}
                        />
                    ))
                    :
                    <div className={styles.noProducts}>{config.noProducts}</div>
                }
            </div>
        </div>
    );
};

const Home = ({
    setPlayer,
    config,
    windowSize,
    isLoading,
    makeRequest,
    scrollToComponent,
    getUrlForShop,
    logout
}) => {
    let player = localStorage.getItem('shishiFreeGiftPlayerInfo');
    player = JSON.parse(player);

    let isFirstPageViewLogin = useRef(true);
    let isFirstPageViewShop = useRef(true);
    const { isLogged, error } = useSelector((state) => state.rootReducer);
    const dispatch = useDispatch();
    const FindIdComponent = useRef(null);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [inputValueId, setInputValueId] = useState('');
    const [inputValueName, setInputValueName] = useState('');
    const [hasLoginError, setHasLoginError] = useState(false);
    const [errorLoginMessage, setErrorLoginMessage] = useState('');
    const [age, setAge] = useState(null);
    const [freePacks, setFreePacks] = useState([]);
    const [offerPopupInfo, setOfferPopupInfo] = useState({});

    const onChangeId = (val) => {
        let id = val.trim();
        id = id.replace(/[^0-9]/g, '');
        if (INPUT_REQUIRE_LENGTH_ID) {
            id = id.slice(0, INPUT_REQUIRE_LENGTH_ID);
        }
        setInputValueId(id);
    }

    const onChangeName = (val) => {
        let name = val.trim();
        if (INPUT_REQUIRE_LENGTH_NAME) {
            name = name.slice(0, INPUT_REQUIRE_LENGTH_NAME);
        }
        setInputValueName(name);
    }

    const onChangeAge = (val) => {
        // manage data if needed
        setAge(val.value);
    }

    const verifyAgeAndSendId = () => {
        if (!inputValueId.length) {
            setHasLoginError(true);
            setErrorLoginMessage('EMPTY_INPUT_ID');
            return;
        }
        if (!inputValueName.length) {
            setHasLoginError(true);
            setErrorLoginMessage('EMPTY_INPUT_NAME');
            return;
        }
        if (age && (age >= MIN_YEARS_TO_PURCHASE)) {
            logTheUser();
        } else {
            setHasLoginError(true);
            setErrorLoginMessage('ENTER_AGE');
        }
    }

    const logTheUser = async (username, userId, userAge) => {
        let pId = userId || inputValueId;
        let pName = username || inputValueName;
        let pAge = userAge || age;

        const playerInfo = {username: pName, userId: pId, age: pAge};
        const data = await makeRequest('post','login', {...playerInfo});
        if (data && data.success) {
            setInputValueId('');
            setInputValueName('');
            setAge(null);
            localStorage.setItem('shishiFreeGiftPlayerInfo', JSON.stringify(playerInfo));
            dispatch(logUserIn());
            setPlayer(playerInfo);
        }
    }

    const getOffers = async () => {
        const data = await makeRequest('post', 'loadProducts', {username: player.username, userId: player.userId, isFreeGift: true});
        if (data && data.categories) {
            setFreePacks(data.categories);
        }
    }

    const openOfferPopupInfo = (info) => {
        // do something if needed and set the info
        setOfferPopupInfo(info);
    }

    const closeOfferPopupInfo = () => {
        // do something if needed and set the info to none
        setOfferPopupInfo({});
    }

    const claimFreeGift = async (pack) => {
        const response = await makeRequest('post', 'claimFree', {
            username: player.username,
            userId: player.userId,
            pack: pack
        });

        if (!response || !response.intentionId) {
            navigate('/checkout-result', { state: {success: false} }); 
        } else {
            navigate('/checkout-result', {state: {success: true, transactionId: response.intentionId}});
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [isLogged]);

    useEffect(() => {
        const pId = searchParams.get('pid');
        const pName = searchParams.get('pname');
        const pAge = searchParams.get('age');

        if (pId && pName && pAge) {
            logout(pId, pName);
            logTheUser(pName, pId, pAge);
        }

    }, [searchParams]);

    useEffect(() => {
        let player = localStorage.getItem('shishiFreeGiftPlayerInfo');
        player = JSON.parse(player);
        const pId = searchParams.get('pid');
        const pName = searchParams.get('pname');
        const pAge = searchParams.get('age');

        if ((pId && pId.length)
            && (pName && pName.length)
            && !pAge
        ) {
            onChangeId(pId);
            onChangeName(pName);

            if (player && player.username && player.userId && player.age) {
                logout();
            }
        }

    }, [searchParams]);

    useEffect(() => {
        let player = localStorage.getItem('shishiFreeGiftPlayerInfo');
        player = JSON.parse(player);
        if (isLogged || (player && player.username && player.userId && player.age)) {
            dispatch(logUserIn());
            setPlayer(player);
            getOffers();
        }
    }, [isLogged]);

    useEffect(() => {
        if (!isLogged && isFirstPageViewLogin) {
            window.gtag('event', 'screen_view', {
                'app_name': 'Shishi free gift',
                'screen_name': 'login'
            });
            window.gtag('event', 'page_view', {
                'app_name': 'Shishi free gift',
                'page_title': 'login'
            });
            isFirstPageViewLogin = false;
        }
        if (isLogged && isFirstPageViewShop) {
            window.gtag('event', 'screen_view', {
                'app_name': 'Shishi free gift',
                'screen_name': 'shop'
            });
            window.gtag('event', 'page_view', {
                'app_name': 'Shishi free gift',
                'page_title': 'shop'
            });
            isFirstPageViewShop = false;
        }
    }, []);

    return (
        <div className={`${styles.loginWrapper} ${isLogged ? styles.additionalSpace : ''}`}>
            {!isLogged ?
                <img src={Logo} className={styles.logo} alt={''} />
                :
                null
            }
            <a
                href={getUrlForShop()}
                target={'_blank'}
                onClick={() => isLogged ?
                    window.gtag('event', 'banner_clicked', {
                        'app_name': 'Shishi free gift',
                        'banner_state': 'after_login'
                    })
                    :
                    window.gtag('event', 'banner_clicked', {
                        'app_name': 'Shishi free gift',
                        'banner_state': 'before_login'
                    })
                }
            >
                <img src={DailyFreeGiftBanner} className={styles.banner} alt={''} />
            </a>
            <div className={styles.welcomeTitle}>{config.getFreeGiftEveryday}</div>
            <div className={styles.loginInfo}>{config.needToLogIn}</div>
            {!isLogged ?
                <div className={styles.ctaWrapper}>
                    <img src={IconInfo} className={styles.iconInfo} alt={''}
                         onClick={() => scrollToComponent(FindIdComponent)}/>
                    <img src={CTABackground} className={styles.ctaBackground} alt={''}/>
                    <div className={styles.loginTitle}>{config.headerTitle}</div>
                    <div className={styles.enterIdText}>{config.signWithId}</div>
                    <div className={styles.inputWrapper}>
                        <input
                            placeholder={config.idPlaceHolder}
                            onFocus={() => hasLoginError ? setHasLoginError(false) : null}
                            onChange={(e) => {
                                const val = (e.target.value);
                                onChangeId(val);
                            }}
                            value={inputValueId}
                        />
                    </div>
                    {CONFIG_LOGIN.hasUsernameConfirm ?
                        <>
                            <div className={styles.enterName}>{config.singWithName}</div>
                            <div className={styles.inputWrapper}>
                                <input
                                    placeholder={config.namePlaceHolder}
                                    onFocus={() => hasLoginError ? setHasLoginError(false) : null}
                                    onChange={(e) => {
                                        const val = (e.target.value);
                                        onChangeName(val);
                                    }}
                                    value={inputValueName}
                                />
                            </div>
                        </>
                        :
                        null
                    }
                    {CONFIG_LOGIN.hasAgeConfirm ?
                        <>
                            <div className={styles.enterBirthday}>{config.enterBirthday}</div>
                            <div className={styles.selectWrapper}>
                                <Select
                                    className={styles.ageSelect}
                                    options={ageInputData.years}
                                    defaultValue={age}
                                    onChange={onChangeAge}
                                    onFocus={() => hasLoginError ? setHasLoginError(false) : null}
                                    placeholder={config.birthdayPlaceholder}
                                    isSearchable={false}
                                />
                            </div>
                        </>
                        :
                        null
                    }
                    <div className={styles.errMessage}>{hasLoginError ? config.errors[errorLoginMessage] : null}</div>
                    <ButtonMain text={config.proceed} onClick={verifyAgeAndSendId}/>
                </div>
                :
                <div className={styles.packs}>
                    {freePacks && freePacks.length ?
                        freePacks.map((category, index) => {
                            return (
                                <CSSTransition
                                    key={index}
                                    in={!category.isHidden}
                                    timeout={400}
                                    classNames="fade"
                                    unmountOnExit
                                >
                                    {renderOffersNormal(category, claimFreeGift, openOfferPopupInfo, config)}
                                </CSSTransition>
                            )
                        })
                        :
                        !isLoading ?
                            <div className={styles.noProductsGeneral}>{config.noProducts}</div>
                            :
                            null
                    }
                </div>
            }
            {isLoading ? <Loading withOverlay={true} /> : null}
            {error.hasError ? <PopupInfo text={error.message} /> : null}
            {Object.keys(offerPopupInfo).length ? <OfferPopupInfo info={offerPopupInfo} claimFreeGift={claimFreeGift} closeOfferPopupInfo={closeOfferPopupInfo} /> : null}
            {!isLogged ?
                <>
                    <div className={'dummy-component-for-ref'} ref={FindIdComponent}></div>
                    <FindId config={config}/>
                </>
                :
                null
            }
        </div>
    );
};

export default withRequestHandler(Home);