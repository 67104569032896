import React from 'react';

import styles from './OffersLayout3.module.scss';

import OfferTimer from "../../OfferTimer/OfferTimer";
import {FREE_TEXT} from "../../../../shared/constants";
import LoadableImage from "../../../LoadableImage/LoadableImage";

const OffersLayout3 = ({
    packId,
    image,
    priceFormatted,
    popUpImg,
    claimFreeGift,
    openPopupInfo,
    endTime,
    categoryId,
    noTimers,
    isFree,
    isClaimed,
    price,
    priceCurrency
}) => {
    const id = packId;

    return (
        <div>
            {noTimers ?
                null
                :
                endTime ?
                    <OfferTimer endTime={endTime} isFree={isFree} />
                    :
                    <div className={styles.endsIn}></div>
            }
            <div className={`${styles.wrapper} ${isClaimed ? styles.claimed : null}`}>
                <LoadableImage img={image} />
                <div className={styles.offerLayoutPrice} onClick={() => claimFreeGift({
                    packId,
                    categoryId,
                    price,
                    priceCurrency
                })}>
                    <div className={styles.offerPrice}>{FREE_TEXT}</div>
                </div>
                {popUpImg && openPopupInfo ?
                    <div
                        className={styles.buttonInfo}
                        onClick={() => openPopupInfo({
                            img: popUpImg,
                            id: id,
                            categoryId: categoryId,
                            price: priceFormatted,
                            isFree: isFree,
                            isClaimed: isClaimed,
                            priceCurrency: priceCurrency,
                            priceForRequest: price
                        })}
                    ></div>
                    :
                    null
                }
            </div>
        </div>
    );
};

export default OffersLayout3;