import React from 'react';
import {NavLink} from 'react-router-dom';

import styles from './FindId.module.scss';
import step1 from '../../assets/images/problem_finding_your_id_1.png';
import step2 from '../../assets/images/problem_finding_your_id_2.png';
import loginArrow from '../../assets/images/login_arrow_gold.png';

import {CONFIG_LOGIN} from "../../shared/constants";

const FindId = ({ config }) => {

    return (
        <div className={`${styles.wrapper} ${!CONFIG_LOGIN.isFindIdLink ? styles.inlogin : ''}`}>
            <div className={styles.titleWrapper}>
                <img className={styles.loginArrow} src={loginArrow} alt={''} />
                <div className={styles.title}>{config.findIdTitle}</div>
                <img className={styles.loginArrow} src={loginArrow} alt={''} />
            </div>
            <div className={styles.infoWrapper}>
                <div className={styles.step1Text}>{config.findIdStep1}</div>
                <img className={styles.step1Img} src={step1} alt={''} />
                <div className={styles.step2Text}>{config.findIdStep2}</div>
                <img className={styles.step2Img} src={step2} alt={''} />
                {CONFIG_LOGIN.isFindIdLink ?
                    <NavLink to={'/'} className={styles.backToLogin}>{config.backToLogin}</NavLink>
                    :
                    null
                }
            </div>
        </div>
    );
};

export default FindId;