import React, {useState, useEffect, useCallback} from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

import './App.scss';

import {BANNER_URL, MOBILE_VIEW_BREAKPOINT} from './shared/constants';
import {mainConfig} from "./shared/mainConfig";
import Home from "./pages/Home/Home";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import {clearStorageUserData} from "./shared/helpers";
import {logUserOut, logUserIn, setLanguageConfig} from "./redux/rootReducer";
import CheckoutResult from "./pages/CheckoutResult/CheckoutResult";
import NotFound from "./pages/NotFound/NotFound";

function App() {
    const {isLogged} = useSelector((state) => state.rootReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config, setConfig] = useState(mainConfig.JP);
    const [windowSize, setWindowSize] = useState({});
    const [player, setPlayer] = useState({});
    const [currentLanguage, setCurrentLanguage] = useState('JP');

    const handleResize = useCallback(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
            isMobileView: window.innerWidth <= MOBILE_VIEW_BREAKPOINT
        });
    }, []);

    const logout = () => {
        clearStorageUserData();
        dispatch(logUserOut());
        navigate('/');
    };

    const scrollToComponent = (component) => {
        component.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const getUrlForShop = (url) => {
        let player = localStorage.getItem('shishiFreeGiftPlayerInfo');
        player = JSON.parse(player);
        if (player && player.userId && player.username) {
            const searchParams = `?pid=${player.userId}&pname=${player.username}&age=${player.age}`;

            return BANNER_URL + searchParams;
        } else {
            return BANNER_URL;
        }
    };

    useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize, false);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [handleResize]);

    useEffect(() => {
        let storedPlayer = localStorage.getItem('shishiFreeGiftPlayerInfo');
        storedPlayer = JSON.parse(storedPlayer);
        const isOnLoginScreen = window.location.pathname === '/';
        if (!isOnLoginScreen && (!storedPlayer || (!storedPlayer.userId || !storedPlayer.username))) {
            logout();
        }
        if (!isLogged && storedPlayer && storedPlayer.userId && storedPlayer.username) {
            dispatch(logUserIn());
            setPlayer(storedPlayer);
        }
        if (isLogged && storedPlayer && !player.userId && !player.username) {
            setPlayer(storedPlayer);
        }
    });

    useEffect(() => {
        dispatch(setLanguageConfig(mainConfig[currentLanguage]));
        setConfig(mainConfig[currentLanguage]);
    }, [currentLanguage]);

    return (
        <React.Fragment>
            {isLogged ?
                <Header
                    currentLanguage={currentLanguage}
                    setCurrentLanguage={setCurrentLanguage}
                    windowSize={windowSize}
                    config={config}
                    player={player}
                    logout={logout}
                />
                :
                null
            }
            <Routes>
                <Route path={'/'} element={
                    <Home
                        setPlayer={setPlayer}
                        config={config}
                        windowSize={windowSize}
                        scrollToComponent={scrollToComponent}
                        getUrlForShop={getUrlForShop}
                        logout={logout}
                    />
                } />
                <Route path={'checkout-result'} element={<CheckoutResult config={config} getUrlForShop={getUrlForShop} />}/>
                { /* 404 */}
                <Route path={'*'} element={<NotFound config={config}/>}/>
            </Routes>
            <Footer config={config} windowSize={windowSize} isLoginPage={true} />
        </React.Fragment>
    );
}

export default App;
