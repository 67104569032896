import React, {useState} from 'react';
import {request} from "../shared/request";
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from "react-router-dom";

import {
    logUserOut,
    setMaintenance,
    startLoading,
    stopLoading,
    showGeneralError,
    clearSelectedOffer
} from "../redux/rootReducer";
import {clearStorageUserData} from "../shared/helpers";
import {ENV} from "../shared/constants";

const withRequestHandler = (Component) => (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { languageConfig } = useSelector(state => state.rootReducer);
    const [isLoading, setIsLoading] = useState(false);

    const makeRequest = async (method, url, payload) => {
        if (!method || !url) {
            return;
        }
        let response = {};
        try {
            dispatch(startLoading());
            setIsLoading(true);

            if (method === 'post') {
                response = await request.post(`${url}`, payload);
            } else {
                response = await request.get(url);
            }

            if (!response) {
                dispatch(showGeneralError(languageConfig.errors.WENT_WRONG));
                return;
            }
            if (response.canShop && response.canShop === false) {
                clearStorageUserData();
                dispatch(logUserOut);
                return;
            }
            if (response.maintenance) {
                dispatch(clearSelectedOffer);
                dispatch(setMaintenance());
                return;
            }
            if (!response.success) {
                const pathname = window.location.pathname;
                if (pathname !== '/' && pathname !== '/shop') {
                    navigate('/');
                }
                dispatch(clearSelectedOffer);
                if (response.error) {
                    if (typeof response.error === 'object') {
                        dispatch(showGeneralError(response.error.message));
                    } else {
                        dispatch(showGeneralError(response.error));
                    }
                } else {
                    dispatch(showGeneralError(languageConfig.errors.WENT_WRONG));
                }
                return;
            }

            return response;
        } catch (err) {
            dispatch(showGeneralError(ENV === 'test' ? err.message : null));
        } finally {
            dispatch(stopLoading());
            setIsLoading(false);
        }
    }

    return <Component {...props} makeRequest={makeRequest} isLoading={isLoading} />
};

export default withRequestHandler;