import React from 'react';

import styles from './Footer.module.scss';
import Logo from '../../assets/images/6waves_logo.png';
import Background from '../../assets/images/footer_container.png';
import BackgroundMobile from '../../assets/images/footer_mobile.png';

const Footer = ({ config, windowSize, isLoginPage }) => {

    return (
        <div className={`${styles.footerContainer} ${isLoginPage ? styles.login : ''}`}>
            {/* {windowSize.isMobileView ?
                <img className={styles.backgroundMobile} src={BackgroundMobile} alt={''} />
                :
                <img className={styles.background} src={Background} alt={''} />
            } */}
            <div className={styles.footerWrapper}>
                <img className={styles.logo} src={Logo} alt={''} />
                <div className={styles.termsWrapper}>
                    <a target={'_blank'} href={'https://shishi.6waves.jp/rule.html'} className={styles.terms}>{config.termsAndConditions}</a>
                    <a target={'_blank'} href={'https://shishi.6waves.jp/privacypolicy.html'} className={styles.terms}>{config.privacyPolicy}</a>
                    <a target={'_blank'} href={'https://shishi.6waves.jp/paymentoffunds.html'} className={styles.terms}>{config.paymentoffunds}</a>
                    <a target={'_blank'} href={'https://shishi.6waves.jp/fundsettlement.html'} className={styles.terms}>{config.fundsettlement}</a>
                    <a target={'_blank'} href={'http://6waves.jp/support'} className={styles.terms}>{config.support}</a>
                </div>
                <div>&copy; Six Waves Inc. All Rights Reserved.</div>
            </div>
        </div>
    );
};

export default Footer;