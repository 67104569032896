import React, {useState} from 'react';

import styles from './LoadableImage.module.scss';
import Loading from "../Loading/Loading";

const LoadableImage = (props) => {
    const [isLoaded, setIsLoaded] = useState(false);

    return (
        <>
            <img
                alt={'placeholder'}
                src={props.img}
                className={styles.imagePlaceholder}
                style={props.style}
                onLoad={() => setIsLoaded(true)}
            />
            {isLoaded ?
                <img
                    className={styles.image}
                    style={props.style}
                    src={props.img}
                    alt={''}
                />
                :
                <Loading />
            }
        </>
    );
};

export default LoadableImage;