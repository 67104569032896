import React from 'react';

import styles from './ButtonMain.module.scss';
import btnBackground from '../../../assets/images/btn_orange_107x55.png';
import btnBackgroundLogout from '../../../assets/images/btn_remove.png';

const ButtonMain = ({ text, onClick, isSmall, isNegative, isDisabled, isLogout, isBig }) => {

    return (
        <div
            className={`${styles.buttonMain} ${isSmall ? styles.small : ''} ${isLogout ? styles.logout : ''} ${isBig ? styles.big : ''}`}
            onClick={!isDisabled ? () => onClick() : null}
        >
            <img className={styles.btnBackground} src={(isLogout || isNegative) ? btnBackgroundLogout : btnBackground} alt={''} />
            <div className={styles.btnText}>{text}</div>
        </div>
    );
};

export default ButtonMain;