export const getPossibleYears = () => {
    const minYears = 18;
    const maxYears = 150;
    const years = [];
    for (let i = minYears; i <= maxYears; i++) {
        years.push({value: i, label: i});
    }
    return years;
}

export const addLeadingZeroes = (number, minCharacters) => {
    let numberToSlice = '';
    for (let i = 0; i < minCharacters; i++) {
        numberToSlice = numberToSlice + '0';
    }
    return (numberToSlice + number).slice(-minCharacters);
};

export const validateEmail = (email) => {
    if (!email.trim().length) {
        return null;
    }

    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        );
};

export const clearStorageUserData = () => {
    localStorage.removeItem('shishiFreeGiftPlayerInfo');
};

/**
 * Returns an object of key-value pairs for the query params
 * Strips the leading ? or #
 * example query:
 *      #param1=2&param2=4
 *      ?param1=2&param2=4
 * @param query
 */
export const getQueryParams = (query) => {
    if (query) {
        query = query.slice(1);
        query = query.replace('&amp;', '&');
        return query.split('&').reduce((prev, current) => {
            const [currentParam, currentValue] = current.split('=');
            return {
                ...prev,
                [currentParam]: decodeURIComponent(currentValue)
            }
        }, {});
    }
};