import {getPossibleYears} from './helpers';

// ***** SHOP CUSTOM CONFIG START *****

export const CONFIG_HEADER = {
    hasNavigation: false,
    hasLanguages: false,
    hasLogo: true,
    hasTitle: true,
    hasContextMenu: true
}

export const CONFIG_LOGIN = {
    hasAgeConfirm: true,
    isFindIdLink: false,
    hasInfoText: false,
    hasUsernameConfirm: true
}

export const API = {
    url: 'https://test-shishi-api.imperiax.org/'
};

export let ENV = 'test';
export let BANNER_URL = 'https://shishi-members.6waves.com/';
export let REDIRECT_URL_AFTER_CLAIM = 'https://shishi-members.6waves.com/shop';
// get the ENV from the .env file(because it has different env when building)
ENV = process.env.REACT_APP_ENV;
if (!ENV) {
    ENV = 'test';
}
if (ENV === 'dev') {
    API.url = 'https://dev-shishi-api.imperiax.info/';
    REDIRECT_URL_AFTER_CLAIM = 'https://dev-shishi-api.imperiax.info';
}
if (ENV === 'test') {
    API.url = 'https://test-shishi-api.imperiax.org/';
    REDIRECT_URL_AFTER_CLAIM = 'https://test-shishi-api.imperiax.org';
}
if (ENV === 'stagingTest') {
    API.url = 'https://shishi-memberstest-api.6waves.com/';
    BANNER_URL = 'https://shishi-memberstest.6waves.com/';
    REDIRECT_URL_AFTER_CLAIM = 'https://shishi-memberstest.6waves.com';
}
if (ENV === 'stagingLive') {
    API.url = 'https://shishi-memberstest-api.6waves.com/';
    BANNER_URL = 'https://shishi-memberstest.6waves.com/';
    REDIRECT_URL_AFTER_CLAIM = 'https://shishi-memberstest.6waves.com';
}
if (ENV === 'live') {
    API.url = 'https://shishi-members-api.6waves.com/';
    BANNER_URL = 'https://shishi-members.6waves.com/';
    REDIRECT_URL_AFTER_CLAIM = 'https://shishi-members.6waves.com';
}

export const MOBILE_VIEW_BREAKPOINT = 1024;

export const ageInputData = {
    years: getPossibleYears()
}

export const MIN_YEARS_TO_PURCHASE = 18;

export const TAX_INCLUDED_TEXT = '（税込）';

export const FREE_TEXT = '無料獲得';

export const NO_CAROUSEL_LAYOUT = 1;

export const SUPPORT_MAIL = 'sample@sample.com'

// ***** SHOP CUSTOM CONFIG END   *****
