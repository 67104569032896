import React from 'react';
import {useNavigate} from "react-router-dom";

import styles from './NotFound.module.scss';

import ButtonMain from "../../components/buttons/ButtonMain/ButtonMain";

const NotFound = ({ config }) => {
    const navigate = useNavigate();

    return (
        <div className={styles.wrapper}>
            <div className={styles.noSuchPageTitle}>404</div>
            <div className={styles.noSuchPageText}>{config.notFound.noSuchPage}</div>
            <div className={styles.backToShop}>
                <ButtonMain
                    text={config.backToShop}
                    onClick={() => navigate('/')}
                    isBig={true}
                />
            </div>
        </div>
    );
};

export default NotFound;