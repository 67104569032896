import React, {useCallback, useRef} from 'react';

import styles from './OfferPopupInfo.module.scss';

import {FREE_TEXT} from "../../shared/constants";
import LoadableImage from "../LoadableImage/LoadableImage";
import useClickOutside from "../../hooks/useClickOutside";

const OfferPopupInfo = ({ info, claimFreeGift, closeOfferPopupInfo }) => {
    const isFirstRender = useRef(true);

    const wrapperRef = useClickOutside(useCallback(() => {
        if (!isFirstRender.current) {
            closeOfferPopupInfo();
        } else {
            isFirstRender.current = false;
        }
    }, [closeOfferPopupInfo]));

    const goToPurchaseOffer = () => {
        closeOfferPopupInfo();
        claimFreeGift({
            price: info.price,
            priceCurrency: info.priceCurrency,
            packId: info.id,
            categoryId: info.categoryId
        });
    }

    return (
        <div className={styles.overlay}>
            <div className={`${styles.wrapper} ${info.isClaimed ? styles.claimed : null}`} ref={wrapperRef}>
                <LoadableImage img={info.img} />
                <div className={styles.offerLayoutPrice} onClick={goToPurchaseOffer}>
                    <div className={styles.offerPrice}>{FREE_TEXT}</div>
                </div>
                <div className={styles.buttonClose} onClick={() => closeOfferPopupInfo()} />
            </div>
        </div>
    );
};

export default OfferPopupInfo;