import React from 'react';

import OffersLayout1 from "./OffersLayout1/OffersLayout1";
import OffersLayout2 from "./OffersLayout2/OffersLayout2";
import OffersLayout3 from "./OffersLayout3/OffersLayout3";

const OffersLayoutsManager = ({ offer, categoryId, claimFreeGift, openPopupInfo, noTimers }) => {
    let OfferLayout = null;

    switch (offer.layout) {
        case 1: OfferLayout = <OffersLayout1
            {...offer}
            categoryId={categoryId}
            claimFreeGift={claimFreeGift}
            noTimers={noTimers}
        />;
            break;
        case 2: OfferLayout = <OffersLayout2
            {...offer}
            categoryId={categoryId}
            claimFreeGift={claimFreeGift}
            openPopupInfo={openPopupInfo}
            noTimers={noTimers}
        />;
            break;
        case 3: OfferLayout = <OffersLayout3
            {...offer}
            categoryId={categoryId}
            claimFreeGift={claimFreeGift}
            openPopupInfo={openPopupInfo}
            noTimers={noTimers}
        />;
            break;
    }

    return (
       OfferLayout
    );
};

export default OffersLayoutsManager;