import React, {useState, useCallback} from 'react';

import styles from './ContextMenu.module.scss';
import playerImg from '../../assets/images/avatar_default.png';
import arrow from '../../assets/images/login_arrow_gold.png';

import ButtonMain from "../buttons/ButtonMain/ButtonMain";
import useClickOutside from "../../hooks/useClickOutside";

const ContextMenu = ({ config, name, id, isMobileView, logout }) => {
    const [isOpen, setIsOpen] = useState(false);

    const wrapperRef = useClickOutside(useCallback(() => {
        if(isOpen) {
            setIsOpen(false);
        }
    }, [isOpen]));

    return (
        <div className={`${styles.wrapper} ${isOpen ? styles.open : null}`} onClick={() => setIsOpen(!isOpen)} ref={wrapperRef}>
            <div className={styles.playerInfo}>
                <div className={styles.playerInfoInnerWrapper}>
                    {!isMobileView ?
                        <img className={styles.playerImg} src={playerImg} alt={''} />
                        :
                        null
                    }
                    <div className={styles.info}>
                        <div className={styles.name}>{name}</div>
                        <div className={styles.id}>#{id}</div>
                    </div>
                </div>
                <img className={styles.arrow} src={arrow} alt={''} />
            </div>
            <div className={styles.horizontalDivider}></div>
            <div className={styles.logout}>
                <ButtonMain
                    text={config.logout}
                    onClick={logout}
                    isLogout={true}
                    isSmall={true}
                />
            </div>
        </div>
    );
};

export default ContextMenu;