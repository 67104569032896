import React from 'react';
import {NavLink} from "react-router-dom";
import {useDispatch} from "react-redux";

import styles from './Header.module.scss';
import Logo from "../../assets/images/logo.png"

import {CONFIG_HEADER} from "../../shared/constants";
import ContextMenu from "../ContextMenu/ContextMenu";
import {clearSelectedOffer} from "../../redux/rootReducer";

const Header = ({
    currentLanguage,
    setCurrentLanguage,
    windowSize,
    config,
    player,
    logout
}) => {
    const dispatch = useDispatch();

    const unselectOffer = () => {
        dispatch(clearSelectedOffer());
    }

    return (
        <header className={styles.headerWrapper}>
            <div className={styles.innerWrapper}>
                {CONFIG_HEADER.hasLogo ?
                    <NavLink to={'/'} onClick={unselectOffer}>
                        <img src={Logo} className={styles.logo} alt={''} />
                    </NavLink>
                    :
                    null
                }
                {CONFIG_HEADER.hasContextMenu ?
                    <div className={styles.contextMenuWrapper}>
                        <ContextMenu
                            config={config}
                            name={player.username}
                            id={player.userId}
                            isMobileView={windowSize.isMobileView}
                            logout={logout}
                        />
                    </div>
                    :
                    null
                }
            </div>
        </header>
    );
};

export default Header;