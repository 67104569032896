import {createSlice} from '@reduxjs/toolkit'
import {mainConfig} from "../shared/mainConfig";

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const rootReducer = createSlice({
    name: 'rootReducer',
    initialState: {
        isInMaintenance: false,
        error: {
            hasError: false,
            message: null,
            isFilterError: null
        },
        languageConfig: mainConfig['JP'],
        selectedOffer: null,
        hasPurchaseIntention: false
    },
    reducers: {
        logUserIn: (state) => {
            state.isLogged = true;
        },
        logUserOut: (state) => {
            state.isLogged = false;
        },
        setMaintenance: (state) => {
            state.isInMaintenance = true;
        },
        startLoading: (state) => {
            state.isLoading = true;
        },
        stopLoading: (state) => {
            state.isLoading = false;
        },
        showGeneralError: (state, action) => {
            const errMessage = typeof action.payload === 'object' ? action.payload.message : action.payload;
            const isFilterError = typeof action.payload === 'object' ? action.payload.isFilterError : null;
            state.error = {
                hasError: true,
                message: errMessage,
                isFilterError: isFilterError
            };
        },
        clearGeneralError: (state) => {
            state.error = {
                hasError: false,
                message: null
            };
        },
        setLanguageConfig: (state, action) => {
            state.languageConfig = action.payload;
        },
        setSelectedOffer: (state, action) => {
            state.selectedOffer = action.payload;
        },
        clearSelectedOffer: (state) => {
            state.selectedOffer = null;
        },
        startPurchaseIntention: (state) => {
            state.hasPurchaseIntention = true;
        },
        clearPurchaseIntention: (state) => {
            state.hasPurchaseIntention = false;
        }
    },
});

// Action creators are generated for each case reducer function
export const {
    logUserIn,
    logUserOut,
    setMaintenance,
    startLoading,
    stopLoading,
    clearGeneralError,
    showGeneralError,
    setLanguageConfig,
    setSelectedOffer,
    clearSelectedOffer,
    startPurchaseIntention,
    clearPurchaseIntention
} = rootReducer.actions

export default rootReducer.reducer