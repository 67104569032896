import React from 'react';

import styles from './OffersLayout2.module.scss';

import OfferTimer from "../../OfferTimer/OfferTimer";
import {FREE_TEXT, TAX_INCLUDED_TEXT} from "../../../../shared/constants";
import LoadableImage from "../../../LoadableImage/LoadableImage";

const OffersLayout2 = ({
    packId,
    image,
    priceFormatted,
    popUpImg,
    selectOfferById,
    openPopupInfo,
    endTime,
    categoryId,
    noTimers,
    isFree,
    isClaimed,
    claimFreeGift,
    price,
    priceCurrency
}) => {
    const id = packId;
    isFree = true;

    return (
        <div>
            {noTimers ?
                null
                :
                endTime ?
                    <OfferTimer endTime={endTime} isFree={isFree} />
                    :
                    <div className={styles.endsIn}></div>
            }
            <div className={`${styles.wrapper} ${isClaimed ? styles.claimed : null}`}>
                <LoadableImage img={image} />
                <div className={`${styles.offerLayoutPrice} ${isFree ? styles.free : null}`}>
                    <div className={styles.offerPrice}>{!isFree ? priceFormatted : FREE_TEXT}</div>
                    {!isFree ? <div className={styles.offerPriceText}>{TAX_INCLUDED_TEXT}</div> : null}
                </div>
                {popUpImg && openPopupInfo && !isClaimed ?
                    <div
                        className={styles.buttonInfo}
                        onClick={() => openPopupInfo({
                            img: popUpImg,
                            id: id,
                            categoryId: categoryId,
                            price: priceFormatted,
                            isFree: isFree,
                            isClaimed: isClaimed,
                            price: price,
                            priceCurrency: priceCurrency
                        })}
                    ></div>
                    :
                    null
                }
                <div
                    className={`${styles.buttonBuy} ${!popUpImg ? styles.normal : ''} ${isFree ? styles.free : ''}`}
                    onClick={() => {
                        if (!isClaimed) {
                            claimFreeGift({
                                packId,
                                categoryId,
                                price,
                                priceCurrency
                            });
                        }
                    }}
                >
                </div>
            </div>
        </div>
    );
};

export default OffersLayout2;