import React from 'react';

import styles from './Loading.module.scss';
import loadingImg from '../../assets/gifs/loading_default_circle.gif';

const loadingBasic = <div className={styles.wrapper}>
    <div className={styles.loadingWrapper}>
        <img src={loadingImg} alt={''} className={styles.loading} />
    </div>
</div>;

const loadingWithOverlay = <div className={styles.overlay}>
    <div className={styles.wrapper}>
        <div className={styles.loadingWrapper}>
            <img src={loadingImg} alt={''} className={styles.loading} />
        </div>
    </div>
</div>;

const Loading = ({ withOverlay }) => {

    return (
        withOverlay ?
            loadingWithOverlay
            :
            loadingBasic
    );
};

export default Loading;