import axios from 'axios';

import {API} from './constants';

const client = axios.create({
    baseURL: API.url,
    headers: {
        'Content-Type': 'application/json',
    }
});

const parseResponse = (data) => {
    // globally look through and modify response if needed
    return data;
};

export const request = {
    async get(url) {
        const result = await client.get(url);
        const data = result.data || { empty: true };
        return parseResponse(data);
    },

    async post(url, params) {
        params = params || {};
        const result = await client.post(url, params);
        const data = result.data || { empty: true };
        return parseResponse(data);
    }
};