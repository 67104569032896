export const mainConfig = {
    'JP': {
        menus: [
        ],
        headerTitle: '会員専用特別サイト',
        signWithId: '領主IDを入力してください',
        idPlaceHolder: '領主ID',
        singWithName: '領主名を入力してください',
        namePlaceHolder: '領主名',
        enterBirthday: '年齢を選択してください',
        birthdayPlaceholder: '年齢入力',
        emailInput: 'E-Mailアドレス（購入履歴送付用）',
        emailInputPlaceholder: 'E-Mailアドレスをご入力ください。',
        proceed: '次へ',
        itemOffers: 'ジェム',
        problemFindingId: '『君主ID』を忘れた場合はこちら',
        errors: {
            WENT_WRONG: '予期せぬ不具合が発生しました',
            PLAYER_NOT_FOUND: '入力された『君主ID』は存在しません。\n 再度お試しいただくか、以下の「君主IDと君主名の確認方法」をお確かめください。',
            EMPTY_INPUT_ID: '領主IDを入力してください',
            EMPTY_INPUT_NAME: '領主名を入力してください',
            ENTER_AGE: '年齢を選択してください。',
            AGE_NEEDED: '18歳以上の成年の方のみ、本サイトをご利用いただけます',
            MAIL_EMPTY: 'E-Mailアドレスが入力されていません。',
            MAIL_INVALID: '正しいE-Mailアドレスをご入力ください。'
        },
        loginTitle: 'ショップ',
        logout: 'ログアウト',
        reviewOrder: 'ご購入内容最終確認',
        remove: '商品削除',
        item: '商品',
        discount: '割引',
        orderTotal: '合計：',
        orderName: '商品名：',
        orderPrice: '価格：',
        orderQuantity: '個数：１個',
        checkout: '購入手続き',
        backToShop: '獅子商店に戻る',
        proceedToCheckout: 'この内容で購入する',
        notFound: {
            error: '不具合が発生しました。\n 再度お試しください。(エラーコード：404)',
            noSuchPage: '該当のページは存在しません'
        },
        checkoutResult: {
            successTitle: '手続きが完了しました',
            transactionId: '注文ID：',
            successInfo_1: 'ゲーム内のメールボックスに購入したアイテムが届きます。',
            successInfo_2: 'なお、メールが届くまで数分かかる場合があります。',
            successInfo_3: 'ご了承ください。',
            successInfo2_1: 'この画面のスクリーンショットを撮影し保存してください。',
            successInfo2_2: 'または上記の注文IDを必ず控えておいてください。',
            successInfo2_3: 'ご相談の際に必要な番号となります。',
            errorTitle: '購入手続き中に問題が発生しました。',
            errorInfo: '再度お試しいただくか、弊社サポートまでお問い合わせください：'
        },
        findIdTitle: '領主IDと領主名の確認方法',
        findIdStep1: '1. 主城画面の左上（赤い枠）にあるアイコンをタップしてください',
        findIdStep2: '2. 次の画面の中央一番上（１）には領主名、一番右下（２）にはIDが書かれています、コピーをタップするとIDのコピーができます',
        findIdStep3: '',
        findIdStep4: '',
        backToLogin: 'ログインに戻る',
        offerEnds: '残り時間：',
        agreeWithTermsAndCond: '私は利用規約に同意して、上記の商品を購入します',
        privacyPolicy: 'プライバシーポリシー',
        termsAndConditions: '利用規約',
        paymentoffunds: '特定商取引法',
        fundsettlement: '資金決済法',
        support: '問い合わせ',
        filter: '絞り込み',
        filterClose: '適用して閉じる',
        filterErr: '全てのカテゴリーを無効にすることはできません',
        noProducts: '該当する商品は存在しません。',
        free: '無料獲得',
        dailyGiftOfficial: '',
        needToLogIn: "ログインしてゲーム内で利用できる無料アイテムを獲得しよう。\n毎日配布！　毎日ログイン！　毎日無料獲得しよう！",
        freeGift: 'プレゼント',
        getFreeGiftEveryday: '毎日無料プレゼントをゲット！',
        findMoreFreeGifts: 'この機会にぜひ無料アイテムを獲得してください。'
    }
}