import React from 'react';
import Countdown, {formatTimeDelta} from "react-countdown";
import {CSSTransition} from "react-transition-group";

import styles from './OfferTimer.module.scss';
import {addLeadingZeroes} from "../../../shared/helpers";

const OfferTimer = ({ endTime, isFree }) => {
    const parsedTime = endTime * 1000;
    const date = new Date(parsedTime);
    const day = addLeadingZeroes(date.getDate(), 2);
    const month = addLeadingZeroes(date.getMonth() + 1, 2);
    const timeHours = addLeadingZeroes(date.getHours(), 2);
    const timeMinutes = addLeadingZeroes(date.getMinutes(), 2);

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            return <span></span>;
        } else {
            // Render a countdown
            return (
                <span className={styles.hoursLeft}>
                    {/*the original prop daysInHours is not working for some reason...*/}
                    {days > 0 ?
                        (days * 24) + hours
                        :
                        addLeadingZeroes(hours, 2)
                    }
                    :{addLeadingZeroes(minutes, 2)}
                    :{addLeadingZeroes(seconds, 2)}
                </span>
            );
        }
    };

    return (
        <CSSTransition in appear timeout={300} classNames={'fade-in-slide-down'}>
            <div className={styles.endsIn}>
                {isFree ?
                    `受取期限：${month}/${day} (${timeHours}:${timeMinutes})まで`
                    :
                    `購入期限：${month}/${day} (${timeHours}:${timeMinutes})まで`
                }
                <br />
                {'残り時間：'}
                <Countdown date={parsedTime} renderer={renderer} />
            </div>
        </CSSTransition>
    );
};

export default OfferTimer;