import React, {useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';

import styles from './CheckoutResult.module.scss';
// import successImg from '../../assets/images/checkout_success.png';
// import errorImg from '../../assets/images/error.png';
import {API, ENV, REDIRECT_URL_AFTER_CLAIM} from "../../shared/constants";

const successImg = ENV === 'dev' ? 'https://shishi-gifttest.6waves.com/checkout_success.png' : 'https://shishi-gift.6waves.com/checkout_success.png';
const errorImg = ENV === 'dev' ? 'https://shishi-gifttest.6waves.com/error.png' : 'https://shishi-gift.6waves.com/error.png';

const CheckoutResult = ({ config, getUrlForShop }) => {
    let isFirstPageView = useRef(true);
    const { state } = useLocation();
    const [isNoStateCase, setIsNoStateCase] = useState(false);

    let DailyFreeGiftBanner = API.url + 'banner/gift?v=' + Date.now();
    if (state && state.success) {
        DailyFreeGiftBanner = API.url + 'banner/giftSuccess?v=' + Date.now();
    } else {
        DailyFreeGiftBanner = API.url + 'banner/giftFail?v=' + Date.now();
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        if (!state && !isNoStateCase) {
            setTimeout(() => {
                setIsNoStateCase(true);
            }, 1500);
        }
    }, []);

    useEffect(() => {
        if (isFirstPageView.current) {
            let player = localStorage.getItem('shishiFreeGiftPlayerInfo');
            player = JSON.parse(player);

            if (state && state.success) {
                window.location = REDIRECT_URL_AFTER_CLAIM + `?gs=true&pid=${player.userId}&pname=${player.username}&age=${player.age}`;
            } else {
                window.location = REDIRECT_URL_AFTER_CLAIM + `?gf=true&pid=${player.userId}&pname=${player.username}&age=${player.age}`;
            }

            const gaPath = (state && state.success) ? 'completed' : 'fail';
            window.gtag('event', 'screen_view', {
                'app_name': 'Shishi free gift',
                'screen_name': gaPath
            });
            window.gtag('event', 'page_view', {
                'app_name': 'Shishi free gift',
                'page_title': gaPath
            });
            isFirstPageView.current = false;
        }
    }, []);

    return (
        <React.Fragment>
            {state ?
                <div className={styles.backgroundAddOn}>
                    <div className={styles.wrapper}>
                        <a
                            href={getUrlForShop()}
                            target={'_blank'}
                            onClick={() => window.gtag('event', 'banner_clicked', {
                                'app_name': 'Shishi free gift',
                                'banner_state': 'after_claim'
                            })}
                        >
                            <img src={DailyFreeGiftBanner} className={styles.banner} alt={''}/>
                        </a>
                        <div className={styles.result}>
                            <img
                                className={`${styles.img} ${state.success ? styles.successImg : styles.errorImg}`}
                                src={state.success ? successImg : errorImg}
                                alt={''}
                            />
                            <div className={styles.info}>
                                {state.success ?
                                    <div className={styles.resultInfo}>
                                        <div className={styles.title}>
                                            {config.checkoutResult.successTitle}
                                        </div>
                                        <div className={styles.title}>
                                            <span>{config.checkoutResult.transactionId}</span>
                                            <span>{state.transactionId}</span>
                                        </div>
                                        <div className={styles.text}>
                                            <p>
                                                {config.checkoutResult.successInfo_1}
                                            </p>
                                            <p>
                                                {config.checkoutResult.successInfo_2}
                                            </p>
                                            <p>
                                                {config.checkoutResult.successInfo_3}
                                            </p>
                                            <p>
                                                {config.checkoutResult.successInfo2_1}
                                            </p>
                                            <p>
                                                {config.checkoutResult.successInfo2_2}
                                            </p>
                                            <p>
                                                {config.checkoutResult.successInfo2_3}
                                            </p>
                                        </div>
                                    </div>
                                    :
                                    <div className={styles.resultInfo}>
                                        <div className={styles.title}>
                                            {config.checkoutResult.errorTitle}
                                        </div>
                                        <div className={styles.text}>
                                            <p>
                                                {config.checkoutResult.errorInfo}
                                            </p>
                                            <p><a target={'_blank'}
                                                  href={'http://6waves.jp/support/'}>http://6waves.jp/support/</a></p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className={styles.noStateWrapper}>
                    <a href={getUrlForShop()} target={'_blank'}>
                        <img src={DailyFreeGiftBanner} className={styles.banner} alt={''}/>
                    </a>
                    <div className={styles.noProductsGeneral}>{config.noProducts}</div>
                </div>
            }
        </React.Fragment>
    );
};
export default CheckoutResult;